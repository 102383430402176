<template>
  <div>
    <Breadcrumbs page_title="community" :items="breadcrumbs" />
    <v-container fluid>
      <v-row v-if="loadingData" class="mt-5">
        <v-col cols="2" v-for="n in 2" :key="n">
          <v-skeleton-loader class="mx-auto" max-height="180" type="image"></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col
          cols="12"
          sm="12"
          md="3"
          lg="3"
          class="lg5-custom"
          v-for="(item, i) in cardItems"
          :key="i"
        >
          <v-card
            style="border-radius: 16px"
            height="230"
            :style="[
              item.selected == true
                ? { border: '2px solid ' + item.bgcolor }
                : {},
            ]"
            @click="selectCourseCategory(item)"
          >
            <v-card-text justify="center" align="center" style="height: 100%">
              <div class="imgtext"></div>
              <v-img :src="item.image" width="65" class="communityimg"></v-img>
              <p
                class="text-center mt-5"
                style="font-size: 20px; color: #424242"
              >{{ $t(item.name) }}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="showCourseList">
        <v-col cols="12" md="8" lg="8">
          <v-card
            style="
              width: 1314px;
              background: #ffffff 0% 0% no-repeat padding-box;
              box-shadow: 0px 3px 6px #0000000f;
              border-radius: 16px;
              overflow-y: auto;
            "
            class="overflow-y-auto pa-5"
          >
            <v-list>
              <v-list-group
                style="margin-bottom: 0.7rem"
                class="list-Bg"
                v-for="item in courseItems"
                :key="item.id"
                append-icon
                no-action
                :value="item.isOpen"
                @click="toggleIcon(item.id)"
              >
                <template v-slot:activator>
                  <v-icon slot="prependIcon" color="#424242">{{ getIcon(item.id) }}</v-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="ml-4"
                      style="font-size: 18px !important"
                      v-text="item.title"
                    ></v-list-item-title>
                    <!--  -->
                  </v-list-item-content>
                </template>

                <v-list-item class="liststyle">
                  <template>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="2" style="font-size: 16px !important">
                          Q
                          <v-icon class="vertical-bar-icon"></v-icon>
                        </v-col>
                        <v-col>
                          <v-list-item-title
                            style="font-size: 16px !important;"
                            v-text="item.question"
                          ></v-list-item-title>
                        </v-col>
                        <!--  -->
                      </v-row>
                      <v-row class="mt-2" v-if="item.reply != null ">
                        <v-col cols="2" style="font-size: 16px !important">
                          R
                          <v-icon class="vertical-bar-icon"></v-icon>
                        </v-col>
                        <v-col>
                          <v-list-item-title
                            style="font-size: 16px !important; white-space: pre-line;"
                            v-text="item.reply"
                          ></v-list-item-title>
                        </v-col>
                        <!--  -->
                      </v-row>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-group>
            </v-list>
            <v-card-actions class="d-flex justify-center">
              <v-btn
                text
                class="sendbtn text-capitalize mb-5 login-phone"
                @click="sendQuestion = true"
              >{{ $t("sendquestion") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="sendQuestion" persistent width="600">
        <v-card class="pa-3 sendquestion">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-title
              style="font-size: 20px; color: #363636; padding-bottom: 5px;padding-top: 5px;"
            >
              <v-img src="@/assets/images/Iconly-Light-Outline-Send.svg" width="25" class="QA"></v-img>
              <p class="mb-2" style="margin-left: 37px">{{ $t("sendquestion") }}</p>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="sendQuestion = false"
                color="#424242"
                style="
                  border-radius: 8px !important;
                  border: 1px solid rgba(66, 66, 66, 0.05);
                  top: -8px;
                "
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-divider></v-divider>
            <v-card-text style="padding-bottom: 5px;padding-top:5px">
              <div class="pt-1">
                <v-row class="mb-1" style="display: flex; justify-content: center;">
                  <v-col cols="12" class="pb-0">
                    <p style="font-size: 16px; color: #4d4f5c; font-weight: 600">{{ $t("title") }}</p>
                    <v-text-field
                      outlined
                      dense
                      :placeholder=  '$t("entertitle")'
                      hide-details="auto"
                      v-model="titleName"
                      class="rounded-lg"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="pb-1">
                    <p style="font-size: 16px; color: #4d4f5c; font-weight: 600">
                      {{ $t("questionsm") }}
                    </p>
                    <v-textarea
                      outlined
                      :placeholder=  '$t("enterquestion")'
                      :rules="replyRules"
                      required
                      name="input-7-4"
                      v-model="reply"
                    ></v-textarea>
                  </v-col>
                  <div
                    v-if="actionloading"
                    style="position: absolute; top: 0; left: 0; height: 100%; width: 100%; display: flex; justify-content: center; align-items: center;"
                  >
                    <v-progress-circular
                      v-if="actionloading"
                      indeterminate
                      class="loading-circle"
                      color="green"
                    ></v-progress-circular>
                  </div>
                </v-row>
              </div>
            </v-card-text>

            <v-card-actions class="pb-3 d-flex justify-center">
              <v-btn
                class="text-capitalize white--text login-phone"
                width="100"
                color="#A6CC39"
                style="font-size: 16px"
                @click="addReply()"
              >{{ $t("send") }}</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <!-- successDialog -->
      <v-dialog v-model="successDialog" persistent width="380">
        <v-card style="overflow: hidden !important;border-radius: 16px !important;">
          <v-card-title style="font-size: 20px; color: #363636;">
            <v-row class="mt-1">
              <v-col style="text-align: center;">
                <p class="mb-2">{{$t('sendingquestion')}}</p>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              class="text-capitalize mx-auto white--text"
              color="#A6CC39"
              style="font-size: 20px;border-radius: 8px;"
              @click="successDialog = false"
            >{{$t('ok')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";

export default {
  components: {},
  data() {
    return {
      loadingData: false,
      // sendDate : moment(new Date().toISOString().substr(0, 10)).local().format("YYYY-MM-DD HH:mm"),
      sendDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      sendQuestion: false,
      showCourseList: false,
      selectedColor: null,
      successDialog: false,
      title: "",
      reply: "",
      valid: true,
      actionloading: false,
      titleName: "",
      replyRules: [v => !!v || "Question is required"],
      faq_question: [],
      Expert: [],
      courseItems: [],
      breadcrumbs: [
        {
          text: "community"
        }
      ],
      cardItems: [
        {
          bgcolor: "#A6CC39",
          image: require("@/assets/Iconly-Bold-Chat.png"),
          name: "FAQ",
          selected: false
        },
        {
          bgcolor: "#A6CC39",
          image: require("@/assets/Group 9041.png"),
          name: "expertcons",
          selected: false
        }
      ],
      activeItems: []
    };
  },
  computed: {
    icon() {
      return this.isExpanded ? "mdi-chevron-down" : "mdi-chevron-right";
    },
    getIcon() {
      return itemId => {
        return this.activeItems.includes(itemId)
          ? "mdi-chevron-down"
          : "mdi-chevron-right";
      };
    }
  },
  mounted() {
    (async () => {
      this.loadingData = true;
      await this.FAQgetData();
      await this.ExpertgetData();
      this.getNotificationCount();
      this.getFavourite();
      this.loadingData = false;

      if (this.$route.query.name == "Inbox") {
        const temp = {
          id: this.$route.query.id,
          selected: false,
          name: "expertcons"
        };
        this.selectCourseCategory(temp);
      }

      return Promise.resolve();
    })().then(() => {
      this.mountDone = true;
    });
  },
  methods: {
    toggleIcon(itemId) {
      const index = this.activeItems.indexOf(itemId);
      if (index !== -1) {
        this.activeItems.splice(index, 1);
      } else {
        this.activeItems.push(itemId);
      }
    },
    selectCourseCategory(v) {

      if (this.faq_question.length == 0 || this.Expert.length == 0) {
        return false;
      }
      this.showCourseList = v.selected ? false : true;

      this.cardItems.forEach(function(value) {
        if (value.name == v.name) {
          value.selected = !value.selected;
        } else {
          value.selected = false;
        }
      });

      if (this.showCourseList === true) {
        let categorized = [];
       if (v.name === "FAQ") {
            this.title = v.name;
            categorized = this.faq_question;
            this.breadcrumbs = [
              {
                text: "community"
              },
              {
                text: "FAQ"
              }
            ];
          } else {
            this.title = v.name;
            categorized = this.Expert;
            this.breadcrumbs = [
              {
                text: "community"
              },
              {
                text: "expertcons"
              }
            ];
          }
        this.courseItems = categorized;
        if (v.id) {
          this.courseItems = this.courseItems.map(temp => ({
            ...temp,
            isOpen: temp.id == v.id ? true : false
          }));
        } else {
          this.courseItems = this.courseItems.map(temp => ({
            ...temp,
            isOpen: false
          }));
        }

      } else {
        this.breadcrumbs = [
          {
            text: "community"
          }
        ];
      }
    },
    async FAQgetData() {
      let self = this;
      self.loading = true;
      let checkPermission = true;
      if (checkPermission) {
        const res = await self.$axios.get(
          `${self.web_url}QNA/GetMostAskedQuestions?id=` +
            localStorage.getItem("companyID") +
            `&offset=` +
            new Date().getTimezoneOffset()
        );
        this.faq_question = res.data.data
          .map(v => ({
            ...v,
            isOpen: false
          }));
        self.items = res.data.data.map((v, i) => ({
          ...v,
          no: i + 1,
          sendDate: moment(v.sendDate).format("DD/MM/YYYY HH:mm"),
          isOpen: false
        }));
        self.item_no = self.items.length;
      } else {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to view Community FAQ.";
      }
      //self.ExpertgetData(); // to get all result one after another.
      self.loading = false;
    },
    async ExpertgetData() {
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        companyId: localStorage.getItem("companyID")
      };
      const res = await axios.post(
        `${this.web_url}QNA/GetQuestionsByUser`,
        request
      );
      this.Expert = res.data.data.map(v => ({
        ...v,
        isOpen: false
      })).reverse();
      this.courseItems = this.Expert;
    },
    async addReply() {
      let self = this;
      self.actionloading = true;
      if (this.$refs.form.validate()) {
        const res = await self.$axios.post(`${self.web_urlV4}QNA/AddQuestion`, {
          question: self.reply,
          title: self.titleName,
          userID: parseInt(localStorage.getItem("UserID")),
          // sendDate: self.sendDate,
          companyId: localStorage.getItem("companyID")
        });
        if (res.data.status == 0) {
          self.sendQuestion = false;
          self.successDialog = true;
          self.ExpertgetData();
        }
        self.$refs.form.reset();
        self.actionloading = false;
      }
    }
  }
};
</script>

<style scoped>
@media (min-width: 1440px) and (max-width: 2560px) {
  .lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}
::v-deep .primary {
  color: #a6cc39 !important;
  background-color: #a6cc39 !important;
  opacity: 1;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
</style>

<style scoped>
::v-deep .list-Bg
  .v-list-group__header.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: rgba(0, 0, 0, 0.87);
  background-color: #42424214;
  height: 70px;
  border-radius: 16px;
}

::v-deep .list-Bg
  .v-list-group__header.v-list-item.v-list-item--active.v-list-item--link.theme--light {
  color: rgba(0, 0, 0, 0.87);
  background-color: #42424214;
  height: 70px;
  /* border-radius: 16px; */
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.imgtext {
  height: 150px;
  background-color: #a6cc39;
  opacity: 0.1;
  border-radius: 8px;
  position: relative;
  z-index: 0 !important;
}
.communityimg {
  position: absolute;
  top: 40%;
  left: 48%;
  transform: translate(-50%, -48%);
}
.imgBg {
  height: 130px;
  opacity: 0.1;
  border-radius: 8px;
  position: relative;
  z-index: 0 !important;
}
.sendbtn {
  color: #ffffff;
  background-color: #a6cc39;
  border-radius: 8px;
  font-size: 16px;
  justify-content: center !important;
}
.liststyle {
  background: #42424208 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 16px 16px;
  opacity: 1;
}
.divider {
  color: #a6cc39;
  margin-bottom: 5px;
  margin-left: 15px;
}
.QA {
  position: absolute;
  top: 18px;
}
.sendquestion {
  border-radius: 18px !important;
}
.vertical-bar-icon {
  display: inline-block;
  height: 20px; /* Adjust height as needed */
  border-right: 2px solid #a6cc39; /* Adjust color and style as needed */
  margin-left: 25px;
}
</style>

<style scoped>
.login-phone {
  color: #fff;
  font-size: 16px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.login-phone:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.login-phone:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.login-phone:hover {
  transition: 0.25s;
}
.login-phone:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
</style>